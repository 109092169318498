export const Resultcontent = [
  {
    id: 0,
    rating: "4.44(192)",
    img: "./card_1.jpg",
    styles: "bigcontents__list1",
    title: "28 Event Space",
    type: "300-400 guest venue",
    location: "Kansas City",
    host: "Mike",
    amenities: "Ceremony Space · Full Bar · Guest Suites",
    smalldescription: "300-400 guest venue · Open Vendor List",
    price: "$5,000",
    description:
      "Massive 2 bedroom apartment literally SECONDS from Times Square. Comfortable, spacious and well maintained. Two full size bedrooms, separate kitchen, living room, office space. Stainless steel appliances, dishwasher. ",
  },
  {
    id: 1,
    img: "./card_2.jpg",
    rating: "4.75(102)",
    title: "Magnolia",
    type: "300-400 guest venue",
    location: "Brookside",
    host: "Abod",
    amenities: "Ceremony Space · Full Bar",
    smalldescription: "300-400 guest venue · Selective Vendor List",
    price: "$15,000",
    description:
      "This modern one bedroom apartment is found in a luxury apartment building, located in the eclectic neighborhood of Chelsea. Must see attractions include The High Line and Chelsea Market. Step outside and get around the city with ease with access to the A, C and E trains, located at the 34th Street and 8th Avenue subway station and the 1, 2 and 3 trains found at the 34th Street and 7th Avenue subway station.",
  },
  {
    id: 2,
    img: "./card_3.jpg",
    rating: "New",
    title: "The Harlow",
    type: "400-500 guest venue",
    location: "Kansas City",
    host: "Micheal",
    amenities: "Ceremony Space · Guest Suites",
    smalldescription: "200-400 guest venue · Open Vendor List",
    price: "$10,000",
    description:
      "Rooms in apartment located in luxury building for rent. Seeking primarily young professionals/ students/ interns. Enjoy a beautiful and safe home with a young environment.",
  },
  {
    id: 3,
    img: "./card_4.jpg",
    rating: "4.15(35)",
    title: "UNION",
    type: "100-200 guest venue",
    location: "West Bottoms",
    host: "Abod Mike",
    amenities: "Ceremony Space · Full Bar · Guest Suites",
    smalldescription: "300-400 guest venue · Very Selective Vendor List",
    price: "$9,000",
    description:
      "Appointed with sleek furniture, our classic rooms offer the traditional comforts of home in one of the most time-honored hotels in Texas. Our ADA rooms provide you with a spacious bathroom that contains a bath tub with grab bars, and multiple room configurations to choose for your stay with up to 270 square feet and a queen sized bed.",
  },
  {
    id: 4,
    img: "./card_5.jpg",
    rating: "3.75(162)",
    title: "The Abbott",
    type: "300-400 guest venue",
    location: "The Crossroads",
    host: "Toju",
    amenities: "Ceremony Space · Full Bar · Guest Suites",
    smalldescription: "150-200 guest venue · Open Vendor List",
    price: "$7,500",
    description:
      "This modern one bedroom apartment is found in a luxury apartment building, located near the lively Theater District. This area is a tourist’s paradise, as it home to areas such as Times Square, Radio City Music Hall and Rockefeller Center. Step outside and get around the city with ease, as the 1 train is easily accessible from the 50th Street and Broadway subway station.",
  },
  {
    id: 5,
    img: "./card_6.jpg",
    rating: "4.55(155)",
    title: "The Guild KC",
    type: "300-400 guest venue",
    location: "Kansas City",
    host: "abod micheal",
    amenities: "Ceremony Space · Full Bar · Guest Suites",
    smalldescription: "50-100 guest venue · Selective Vendor List",
    price: "$8,000",
    description:
      "Prime Times square location with access to restaurants & bars.",
  },
];

export const Maincontents = [
  {
    id: 8,
    img: "./card__1.jpg",
    title: "Unique Venues",
    description: "Spaces that offer a bit more personality.",
  },
  {
    id: 9,
    img: "./card__2.jpg",
    title: "All Venues",
    description:
      "Browse all venues on Vow'd.",
  },
  {
    id: 10,
    img: "./card__3.jpg",
    title: "Weekenders",
    description: "Venues offering a full weekend experience.",
  },
];

export const Bigcontents = [
  {
    id: 11,
    img: "./main-card_1.jpg",
    styles: "bigcontents__list1",
    description: "Transparent pricing.",
  },
  {
    id: 12,
    img: "./main-card_2.jpg",
    description: "Full list of vendor restrictions.",
  },
  {
    id: 13,
    img: "./main-card_3.jpg",
    styles: "bigcontents__list3",
    description: "Max capacity and up-to-date availability.",
  },
];

export const Content = [
  {
    id: 0,
    img: "./card_1.jpg",
    styles: "bigcontents__list1",
    title: "28 Event Space",
    price: "$1,000/night",
    description: "Beauiful industrial vibes with great natural light",
  },
  {
    id: 1,
    img: "./card_2.jpg",
    title: "Magnolia",
    price: "$8,500/night",
    description: "Say Hello to the newst venue in Kansas City!",
  },
  {
    id: 5,
    img: "./card_3.jpg",
    title: "The Harlow",
    styles: "bigcontents__list3",
    price: "$9,500/night",
    description:
      "Quaint venue tucked away within downtown.",
  },
  {
    id: 3,
    img: "./card_4.jpg",
    styles: "bigcontents__list4",
    title: "UNION",
    price: "$7,500/night",
    description: "A historic space in the heart of the West Bottoms",
  },
];
